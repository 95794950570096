(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/loading-state/assets/javascripts/loading-state-actions.js') >= 0) return;  svs.modules.push('/components/tipsen/loading-state/assets/javascripts/loading-state-actions.js');
"use strict";

const {
  createAction
} = RTK;
const startLoadingAction = createAction('loadingstate/startloading');
const loadingActionContainer = createAction('loadingstate/loadstatecontainer');
const generateLoadingState = _ref => {
  let {
    key,
    pause = 0,
    payload
  } = _ref;
  return loadingActionContainer({
    key,
    pause,
    loadingPayload: payload
  });
};
setGlobal('svs.components.tipsen.loadingState.actions', {
  startLoadingAction,
  loadingActionContainer,
  generateLoadingState
});

 })(window);