(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/loading-state/assets/javascripts/loading-state-middleware.js') >= 0) return;  svs.modules.push('/components/tipsen/loading-state/assets/javascripts/loading-state-middleware.js');
"use strict";

const {
  startLoadingAction
} = svs.components.tipsen.loadingState.actions;
const {
  isPending,
  isRejected,
  isFulfilled
} = RTK;
const loadingStateMiddleware = _ref => {
  let {
    dispatch
  } = _ref;
  const timers = {};
  return next => action => {
    var _action$meta$arg;
    if (isPending(action) && (_action$meta$arg = action.meta.arg) !== null && _action$meta$arg !== void 0 && _action$meta$arg.loadingMeta) {
      const {
        key,
        pause
      } = action.meta.arg.loadingMeta;
      if (pause > 0) {
        timers[action.meta.requestId] = setTimeout(() => {
          dispatch(startLoadingAction({
            key,
            requestId: action.meta.requestId
          }));
        }, pause);
      }
    } else if (isRejected(action) || isFulfilled(action)) {
      if (timers[action.meta.requestId]) {
        clearTimeout(timers[action.meta.requestId]);
      }
    }
    next(action);
  };
};
setGlobal('svs.components.tipsen.loadingState.loadingStateMiddleware', loadingStateMiddleware);

 })(window);