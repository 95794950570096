(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/loading-state/assets/javascripts/loading-state-selectors.js') >= 0) return;  svs.modules.push('/components/tipsen/loading-state/assets/javascripts/loading-state-selectors.js');
"use strict";

const {
  normalizeKey
} = svs.components.tipsen.loadingState.constants;
const selectIsLoading = state => Object.keys(state.loadingState.keys).length > 0;
const selectIsKeyLoading = (state, key) => state.loadingState.keys[normalizeKey(key)] > 0;
const selectIsAnyKeysLoading = (state, keys) => {
  let isLoading = false;
  for (const k of keys) {
    if (state.loadingState.keys[normalizeKey(k)] > 0) {
      isLoading = true;
      break;
    }
  }
  return isLoading;
};
setGlobal('svs.components.tipsen.loadingState.selectors', {
  selectIsLoading,
  selectIsKeyLoading,
  selectIsAnyKeysLoading
});

 })(window);