(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/loading-state/assets/javascripts/loading-state-reducer.js') >= 0) return;  svs.modules.push('/components/tipsen/loading-state/assets/javascripts/loading-state-reducer.js');
"use strict";

const {
  createReducer,
  isPending,
  isRejected,
  isFulfilled
} = RTK;
const {
  startLoadingAction
} = svs.components.tipsen.loadingState.actions;
const {
  normalizeKey
} = svs.components.tipsen.loadingState.constants;
const initial = {
  keys: {},
  loading: {}
};
const loadingStateReducer = createReducer(initial, builder => {
  builder.addCase(startLoadingAction, (state, action) => {
    if (state.loading[action.payload.requestId]) {
      const key = normalizeKey(action.payload.key);
      if (state.keys[key]) {
        state.keys[key]++;
      } else {
        state.keys[key] = 1;
      }
    }
  }).addMatcher(action => {
    var _action$meta$arg;
    return isPending(action) && ((_action$meta$arg = action.meta.arg) === null || _action$meta$arg === void 0 ? void 0 : _action$meta$arg.loadingMeta);
  }, (state, action) => {
    const {
      pause,
      key
    } = action.meta.arg.loadingMeta;
    if (!(pause > 0)) {
      const _key = normalizeKey(key);
      if (state.keys[_key]) {
        state.keys[_key]++;
      } else {
        state.keys[_key] = 1;
      }
    }
    state.loading[action.meta.requestId] = true;
  }).addMatcher(action => {
    var _action$meta$arg2;
    return (isFulfilled(action) || isRejected(action)) && ((_action$meta$arg2 = action.meta.arg) === null || _action$meta$arg2 === void 0 ? void 0 : _action$meta$arg2.loadingMeta);
  }, (state, action) => {
    const {
      key
    } = action.meta.arg.loadingMeta;
    delete state.loading[action.meta.requestId];
    const _key = normalizeKey(key);
    if (state.keys[_key]) {
      state.keys[_key]--;
      if (state.keys[_key] <= 0) {
        delete state.keys[_key];
      }
    }
  });
});
setGlobal('svs.components.tipsen.loadingState.loadingStateReducer', loadingStateReducer);

 })(window);