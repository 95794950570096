(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/loading-state/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/loading-state/assets/javascripts/constants.js');
"use strict";

const LoadingKeys = {
  PIX: 'PIX'
};
const normalizeKey = key => {
  key = key.replace(/\W*/, '').toUpperCase();
  if (key.length < 1) {
    throw new Error('Faulty loading state key');
  }
  return key;
};
setGlobal('svs.components.tipsen.loadingState.constants', {
  LoadingKeys,
  normalizeKey
});

 })(window);